import '@livekit/components-styles';
import React, { useCallback, useEffect, useMemo } from 'react';
import useParticipantSession from '../../../hooks/_useParticipantSession';
import useSession from '../../../hooks/useSession';
import useSessionMode from '../../../hooks/useSessionMode';
import useSessionStatus from '../../../hooks/useSessionStatus';
import useSpotlightSharing from '../../../hooks/useSpotlightSharing';
import { ESessionMode, ESessionStatus } from '../../../sharedModules/interfaces';
import styles from '../styles/ParticipantSession.module.css';
import BreakoutRoom from './BreakoutRoom';
import CompatibilityTest from './CompatibilityTest';
import ParticipantLivestreamRoom from './ParticipantLivestreamRoom';
import ParticipantProctoringRoom from './ParticipantProctoringRoom';

const ParticipantSession: React.FC = () => {
  console.log('ParticipantSession');
  const { livestreamRoom } = useSession();
  const {
    breakoutRoom,
    showCompatibilityTest,
    setShowCompatibilityTest,
    hasCameraPermission,
    setHasCameraPermission,
    hasMicrophonePermission,
    setHasMicrophonePermission,
    setProctoringRoom,
    participantProctoringRoom,
  } = useParticipantSession();

  const { status } = useSessionStatus();

  const { sessionMode } = useSessionMode();

  const {
    spotlightedParticipant,
    isSelectedForSpotlightSharing,
    setIsSelectedForSpotlightSharing,
  } = useSpotlightSharing();

  const livestream_room = useMemo(() => livestreamRoom.room, [livestreamRoom.room]);

  const handlePermissionsChecked = (
    cameraPermission: boolean,
    microphonePermission: boolean,
  ) => {
    setHasCameraPermission(cameraPermission);
    setHasMicrophonePermission(microphonePermission);
  };

  useEffect(() => {
    if (hasCameraPermission && hasMicrophonePermission) {
      setShowCompatibilityTest(false);
    }
  }, [hasCameraPermission, hasMicrophonePermission, setShowCompatibilityTest]);

  const handleCameraToggle = useCallback(
    (enabled: boolean) => {
      if (participantProctoringRoom?.localParticipant) {
        participantProctoringRoom.localParticipant.setCameraEnabled(enabled);
      }
    },
    [participantProctoringRoom],
  );

  if (!livestreamRoom.token || !livestreamRoom.room) {
    return <div>Loading rooms...</div>;
  }

  if (showCompatibilityTest) {
    return <CompatibilityTest onPermissionsChecked={handlePermissionsChecked} />;
  }

  return (
    <div className={`${styles.container} container self-center`}>
      <div className={styles.row}>
        <div
          className={`${styles.livestreamRoom}`}
          style={{
            display: status === ESessionStatus.ONGOING ? 'block' : 'none',
          }}
        >
          <ParticipantLivestreamRoom
            token={livestreamRoom.token}
            room={livestream_room}
            isSelectedForSpotlightSharing={isSelectedForSpotlightSharing}
            setIsSelectedForSpotlightSharing={setIsSelectedForSpotlightSharing}
          />
        </div>
        {status === ESessionStatus.ONGOING && (
          <>
            <div className={`${styles.col12} ${styles.proctoringRoom}`}>
              <ParticipantProctoringRoom
                sessionMode={sessionMode}
                isSelectedForSpotlightSharing={isSelectedForSpotlightSharing}
                setProctoringRoom={setProctoringRoom}
              />
            </div>
            <div className={`${styles.col12} ${styles.proctoringRoom}`}>
              {sessionMode === ESessionMode.BREAKOUT && (
                <BreakoutRoom
                  token={breakoutRoom.token!}
                  room={breakoutRoom.room!}
                  onCameraToggle={handleCameraToggle}
                  spotlightedParticipant={spotlightedParticipant ?? ''}
                />
              )}
            </div>
          </>
        )}
        {status !== ESessionStatus.ONGOING && <div>Session is not active</div>}
      </div>
    </div>
  );
};

export default ParticipantSession;
