import { ESessionStatus, TSessionStatus } from '../sharedModules/interfaces';
import useSessionStore from '../store/sessionStore';

const useSessionStatus = () => {
    const {
        session,
        sessionStatusLoading,
        setSessionStatus,
        startSession,
        endSession,
    } = useSessionStore();

    const changeSessionStatus = async (sessionId: number | undefined, status: TSessionStatus) => {
        if (!sessionId) return;
        if (status === ESessionStatus.ONGOING) {
            await startSession(sessionId);
        } else {
            await endSession(sessionId);
        }
    };

    return {
        status: session?.status ?? ESessionStatus.UPCOMING,
        sessionStatusLoading,
        changeSessionStatus,
        storeSessionStatus: setSessionStatus,
    };
};

export default useSessionStatus;
