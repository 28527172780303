import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ParticipantSession from '../components/session/participant/ParticipantSession';
import useParticipantSession from '../hooks/_useParticipantSession';
import useSession from '../hooks/useSession';
import '../index.css';
import { ERole } from '../sharedModules/interfaces';

const ConferencingSessionLayout = () => {
  const { sessionId } = useParams();
  const { fetchSessionById, session, loading, error, livestreamRoom } = useSession();
  const { breakoutRoom, proctoringRoom } = useParticipantSession();

  const role = ERole.PARTICIPANT;

  const [showDebugStats, setShowDebugStats] = useState(false);

  useEffect(() => {
    if (sessionId) {
      fetchSessionById(Number(sessionId)); // Convert sessionId to number
    }
  }, [fetchSessionById, sessionId]);

  if (!sessionId) {
    return <div>Session ID not found</div>;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="conferencing">
      <h1 style={{ marginBottom: '1em' }}>
        Session - {role} - {session?.name} - {session?.stream_language}
      </h1>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ParticipantSession />
        <button
          className="btn btn-active"
          onClick={() => setShowDebugStats((prev) => !prev)}
          style={{ marginTop: '3rem', alignSelf: 'baseline' }}
        >
          {showDebugStats ? 'Hide Debug Stats' : 'Show Debug Stats'}
        </button>
      </div>
      {showDebugStats && (
        <div style={{ marginTop: '1em' }}>
          {session && <pre>session: {JSON.stringify(session, null, 2)}</pre>}
          {(breakoutRoom?.token || breakoutRoom?.room) && (
            <pre>breakoutRoom: {JSON.stringify(breakoutRoom, null, 2)}</pre>
          )}
          {livestreamRoom && (
            <pre>livestreamRoom: {JSON.stringify(livestreamRoom, null, 2)}</pre>
          )}
          {(proctoringRoom?.token || proctoringRoom?.room) && (
            <pre>proctoringRoom: {JSON.stringify(proctoringRoom, null, 2)}</pre>
          )}
        </div>
      )}
    </div>
  );
};

export default ConferencingSessionLayout;
