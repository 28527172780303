import { Room } from 'livekit-client';
import { useCallback } from 'react';
import { videoCaptureOptions } from '../constants/session';
import {
  ESessionStatus,
  IHandRaised,
  TSessionMode,
  TSessionStatus,
} from '../sharedModules/interfaces';
import useSessionStore from '../store/sessionStore';

const useParticipantSession = () => {
  const {
    session,
    proctoringRoom,
    breakoutRoom,
    getParticipantSessionTokens,
    showCompatibilityTest,
    setShowCompatibilityTest,
    hasCameraPermission,
    setHasCameraPermission,
    hasMicrophonePermission,
    setHasMicrophonePermission,
    participantProctoringRoom,
    setProctoringRoom,
  } = useSessionStore();

  const hostUsername = session?.host.email ?? null;

  const handleParticipantLivestreamRoomData = useCallback(
    async (
      payload: Uint8Array,
      handsRaised: IHandRaised[],
      storeSessionMode: (mode: TSessionMode) => void,
      storeSpotlightSharing: (spotlightSharing: boolean) => void,
      storeHandsRaised: (handsRaised: IHandRaised[]) => void,
      storeSessionStatus: (sessionStatus: TSessionStatus) => void,
      getParticipantTokens: (sessionId: number) => void,
      liveStreamRoom: Room,
      meUsername: string | undefined,
      session_id: number,
    ) => {
      const message = new TextDecoder().decode(payload);
      const data = JSON.parse(message);
      console.log('ParticipantLivestreamRoom Data received:', data);

      if ('mode' in data) storeSessionMode(data.mode);

      if ('spotlight_sharing' in data) storeSpotlightSharing(data.spotlight_sharing);

      if ('raised_hand' in data) {
        const isDuplicate = handsRaised.some(
          (hand: IHandRaised) => hand.user_id === data.raised_hand.user_id,
        );
        if (!isDuplicate) {
          storeHandsRaised([...handsRaised, data.raised_hand]);
        }
      }

      if ('lowered_hand' in data) {
        storeHandsRaised(
          handsRaised.filter(
            (raisedHand: IHandRaised) => raisedHand.user_id !== data.lowered_hand.user_id,
          ),
        );
      }

      if ('select_spotlight' in data) {
        const updatedHandsRaised = handsRaised.map((hand: IHandRaised) =>
          hand.participant_name === data.select_spotlight
            ? { ...hand, is_selected: true }
            : { ...hand, is_selected: false },
        );
        storeHandsRaised(updatedHandsRaised);
        if (data.select_spotlight === meUsername) {
          liveStreamRoom.localParticipant.setCameraEnabled(true, videoCaptureOptions);
          liveStreamRoom.localParticipant.setMicrophoneEnabled(true);
        }
      }

      if ('deselect_spotlight' in data) {
        const updatedHandsRaised = handsRaised.map((hand: IHandRaised) =>
          hand.participant_name === data.deselect_spotlight
            ? { ...hand, is_selected: false }
            : hand,
        );
        storeHandsRaised(updatedHandsRaised);
        if (data.deselect_spotlight === meUsername) {
          liveStreamRoom.localParticipant.setCameraEnabled(false, videoCaptureOptions);
          liveStreamRoom.localParticipant.setMicrophoneEnabled(false);
        }
      }

      if ('session_status' in data) {
        if (data.session_status === ESessionStatus.ONGOING) {
          await getParticipantTokens(session_id);
        }
        storeSessionStatus(data.session_status);
      }
    },
    [],
  );

  return {
    proctoringRoom,
    breakoutRoom,
    handleParticipantLivestreamRoomData,
    setProctoringRoom,
    participantProctoringRoom,
    getParticipantTokens: getParticipantSessionTokens,
    showCompatibilityTest,
    setShowCompatibilityTest,
    hasCameraPermission,
    setHasCameraPermission,
    hasMicrophonePermission,
    setHasMicrophonePermission,
    hostUsername,
  };
};

export default useParticipantSession;
