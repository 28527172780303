import { Room } from 'livekit-client';
import { create } from 'zustand';
import { SessionsState } from '../interfaces';
import axiosInstance from '../services/api';
import {
  ESessionMode,
  ESessionStatus,
  IHandRaised,
  ISession,
  TSessionMode,
  TSessionStatus,
} from '../sharedModules/interfaces';

interface SessionStore extends SessionsState {
  // Actions
  setLoading: (loading: boolean) => void;
  setSession: (sessionData: Partial<SessionsState>) => void;
  setParticipantSessionTokens: (tokens: Partial<SessionsState>) => void;
  storeSessionMode: (mode: TSessionMode) => void;
  storeSpotlightSharing: (spotlight_sharing: boolean) => void;
  storeHandsRaised: (handsRaised: IHandRaised[]) => void;
  setError: (error: string | null) => void;
  setSessionModeLoading: (loading: boolean) => void;
  setSpotlightSharingLoading: (loading: boolean) => void;
  setHandRaisedLoading: (loading: boolean) => void;
  setHostLivestreamRoom: (room: Room) => void;
  setSessionStatusLoading: (loading: boolean) => void;
  setSessionStatus: (status: TSessionStatus) => void;
  setShowCompatibilityTest: (value: boolean) => void;
  setHasCameraPermission: (value: boolean) => void;
  setHasMicrophonePermission: (value: boolean) => void;
  setIsSelectedForSpotlightSharing: (value: boolean) => void;
  setProctoringRoom: (room: Room | null) => void;
  // Async actions
  fetchSessionById: (sessionId: number) => Promise<void>;
  getParticipantSessionTokens: (sessionId: number) => Promise<void>;
  putSessionMode: (args: {
    sessionId: number;
    mode: TSessionMode;
    room_name: string;
  }) => Promise<void>;
  putSpotlightSharing: (args: {
    sessionId: number;
    spotlight_sharing: boolean;
    room_name: string;
  }) => Promise<void>;
  putRaisedHand: (
    handRaised: boolean,
    args: {
      roomName: string;
      session_id: number;
      participantName: string;
      participantId: string;
    },
  ) => Promise<void>;
  putSelectSpotlightParticipant: (args: {
    sessionId: number;
    user_id: number;
  }) => Promise<void>;
  putDeselectSpotlightParticipant: (args: {
    sessionId: number;
    user_id: number;
  }) => Promise<void>;
  startSession: (sessionId: number) => Promise<void>;
  endSession: (sessionId: number) => Promise<void>;
}

const useSessionStore = create<SessionStore>((set, get) => ({
  // Initial state
  session: null,
  breakoutRoom: { room: null, token: null },
  livestreamRoom: { room: null, token: null },
  proctoringRoom: { room: null, token: null },
  proctoringRooms: [],
  loading: false,
  error: null,
  sessionModeLoading: false,
  spotlightSharingLoading: false,
  handRaisedLoading: false,
  sessionStatusLoading: false,
  lk_room: undefined,
  showCompatibilityTest: true,
  hasCameraPermission: false,
  hasMicrophonePermission: false,
  isSelectedForSpotlightSharing: false,
  participantProctoringRoom: null,
  // Actions
  setLoading: (loading: boolean) => set({ loading }),
  setSession: (sessionData: Partial<SessionsState>) => {
    set((state) => ({
      ...state,
      session: sessionData.session ?? state.session,
      breakoutRoom: sessionData.breakoutRoom ?? state.breakoutRoom,
      livestreamRoom: sessionData.livestreamRoom ?? state.livestreamRoom,
      proctoringRoom: sessionData.proctoringRoom ?? state.proctoringRoom,
      proctoringRooms: sessionData.proctoringRooms ?? state.proctoringRooms,
      loading: false,
    }));
  },
  setParticipantSessionTokens: (tokens: Partial<SessionsState>) => {
    set((state) => ({
      ...state,
      breakoutRoom: tokens.breakoutRoom ?? state.breakoutRoom,
      proctoringRoom: tokens.proctoringRoom ?? state.proctoringRoom,
    }));
  },
  storeSessionMode: (mode: TSessionMode) => {
    set((state) => ({
      ...state,
      session: state.session ? { ...state.session, mode } : state.session,
      sessionModeLoading: false,
    }));
  },
  storeSpotlightSharing: (spotlight_sharing: boolean) => {
    set((state) => ({
      ...state,
      session: state.session
        ? {
            ...state.session,
            spotlight_sharing,
            hands_raised: !spotlight_sharing ? [] : state.session.hands_raised,
          }
        : state.session,
      handRaisedLoading: !spotlight_sharing ? false : state.handRaisedLoading,
      spotlightSharingLoading: false,
    }));
  },
  storeHandsRaised: (handsRaised: IHandRaised[]) => {
    set((state) => ({
      ...state,
      session: state.session
        ? {
            ...state.session,
            hands_raised: handsRaised,
          }
        : state.session,
      handRaisedLoading: false,
    }));
  },
  setError: (error: string | null) => {
    set((state) => ({
      ...state,
      error,
      loading: false,
    }));
  },
  setSessionModeLoading: (sessionModeLoading: boolean) => set({ sessionModeLoading }),
  setSpotlightSharingLoading: (spotlightSharingLoading: boolean) =>
    set({ spotlightSharingLoading }),
  setHandRaisedLoading: (handRaisedLoading: boolean) => set({ handRaisedLoading }),
  setHostLivestreamRoom: (room: Room) => set({ hostLivestreamRoom: room }),
  setSessionStatusLoading: (sessionStatusLoading: boolean) =>
    set({ sessionStatusLoading }),
  setSessionStatus: (status: TSessionStatus) => {
    set((state) => {
      const updatedSession = state.session
        ? {
            ...state.session,
            status,
            ...(status !== ESessionStatus.ONGOING
              ? {
                  mode: ESessionMode.FOCUSED,
                  spotlight_sharing: false,
                  hands_raised: [],
                }
              : {}),
          }
        : state.session;

      return {
        ...state,
        session: updatedSession,
        ...(status !== ESessionStatus.ONGOING
          ? {
              breakoutRoom: { room: null, token: null },
              proctoringRoom: { room: null, token: null },
              proctoringRooms: [],
              loading: false,
              error: null,
              sessionModeLoading: false,
              spotlightSharingLoading: false,
              handRaisedLoading: false,
              sessionStatusLoading: false,
              lk_room: undefined,
            }
          : {}),
      };
    });
  },
  setShowCompatibilityTest: (value: boolean) => set({ showCompatibilityTest: value }),
  setHasCameraPermission: (value: boolean) => set({ hasCameraPermission: value }),
  setHasMicrophonePermission: (value: boolean) => set({ hasMicrophonePermission: value }),
  setIsSelectedForSpotlightSharing: (value: boolean) =>
    set({ isSelectedForSpotlightSharing: value }),
  setProctoringRoom: (room: Room | null) => set({ participantProctoringRoom: room }),

  // Async actions (as before)
  fetchSessionById: async (sessionId: number) => {
    set({ loading: true });
    try {
      const response = await axiosInstance.get<Partial<SessionsState>>(
        `/sessions/${sessionId}`,
      );
      const { proctoringRooms, proctoringRoom, ...rest } = response.data;

      if (proctoringRooms) {
        // Host
        set({ ...rest, proctoringRooms, loading: false } as SessionsState);
      } else {
        // Participant
        set({ ...rest, proctoringRoom, loading: false } as SessionsState);
      }
    } catch (error) {
      set({
        error: `An error occurred while fetching the session, ${error}`,
        loading: false,
      });
    }
  },
  getParticipantSessionTokens: async (sessionId: number) => {
    set({ loading: true });
    try {
      const response = await axiosInstance.get(`/sessions/${sessionId}/tokens`);
      set((state) => ({
        ...state,
        breakoutRoom: response.data.breakoutRoom ?? state.breakoutRoom,
        proctoringRoom: response.data.proctoringRoom ?? state.proctoringRoom,
        loading: false,
      }));
    } catch (error) {
      set({
        error: `An error occurred while fetching the participant session tokens, ${error}`,
        loading: false,
      });
    }
  },
  putSessionMode: async ({
    sessionId,
    mode,
    room_name,
  }: {
    sessionId: number;
    mode: TSessionMode;
    room_name: string;
  }) => {
    set({ sessionModeLoading: true });
    try {
      await axiosInstance.put<ISession>(`/sessions/${sessionId}/mode`, {
        mode,
        room_name,
      });
      set((state) => ({
        ...state,
        session: state.session ? { ...state.session, mode } : state.session,
        sessionModeLoading: false,
      }));
    } catch (error) {
      set({
        error: `An error occurred while setting the session mode, ${error}`,
        sessionModeLoading: false,
      });
    }
  },
  putSpotlightSharing: async ({
    sessionId,
    spotlight_sharing,
    room_name,
  }: {
    sessionId: number;
    spotlight_sharing: boolean;
    room_name: string;
  }) => {
    set({ spotlightSharingLoading: true });
    try {
      await axiosInstance.put<ISession>(`/sessions/${sessionId}/spotlight-sharing`, {
        spotlight_sharing,
        room_name,
      });
      set((state) => ({
        ...state,
        session: state.session
          ? {
              ...state.session,
              spotlight_sharing,
              hands_raised: !spotlight_sharing ? [] : state.session.hands_raised,
            }
          : state.session,
        handRaisedLoading: !spotlight_sharing ? false : state.handRaisedLoading,
        spotlightSharingLoading: false,
      }));
    } catch (error) {
      set({
        error: `An error occurred while setting the spotlight sharing, ${error}`,
        spotlightSharingLoading: false,
      });
    }
  },
  putRaisedHand: async (
    handRaised: boolean,
    {
      roomName,
      session_id,
      participantName,
      participantId,
    }: {
      roomName: string;
      session_id: number;
      participantName: string;
      participantId: string;
    },
  ) => {
    set({ handRaisedLoading: true });
    const handsRaised = get().session?.hands_raised ?? [];
    try {
      if (handRaised) {
        // Lower hand
        await axiosInstance.put(`/participants/lower-hand/${roomName}`);
        const updatedHandsRaised = handsRaised.filter(
          (raisedHand: IHandRaised) => raisedHand.participant_name !== participantName,
        );
        set((state) => ({
          ...state,
          session: state.session
            ? { ...state.session, hands_raised: updatedHandsRaised }
            : state.session,
          handRaisedLoading: false,
        }));
      } else {
        // Raise hand
        const resp = await axiosInstance.put<any, any>(
          `/participants/raise-hand/${roomName}`,
          {
            session_id,
            participant_name: participantName,
            participant_id: participantId,
          },
        );
        const updatedHandsRaised = [...handsRaised, resp.data.hand_raised];
        set((state) => ({
          ...state,
          session: state.session
            ? { ...state.session, hands_raised: updatedHandsRaised }
            : state.session,
          handRaisedLoading: false,
        }));
      }
    } catch (error) {
      set({
        error: `An error occurred while raising/lowering hand, ${error}`,
        handRaisedLoading: false,
      });
    }
  },
  putSelectSpotlightParticipant: async ({
    sessionId,
    user_id,
  }: {
    sessionId: number;
    user_id: number;
  }) => {
    try {
      await axiosInstance.put(`/sessions/${sessionId}/spotlight-sharing/select`, {
        user_id,
      });
    } catch (error) {
      set({
        error: `An error occurred while selecting spotlight participant, ${error}`,
      });
    }
  },
  putDeselectSpotlightParticipant: async ({
    sessionId,
    user_id,
  }: {
    sessionId: number;
    user_id: number;
  }) => {
    try {
      await axiosInstance.put(`/sessions/${sessionId}/spotlight-sharing/deselect`, {
        user_id,
      });
    } catch (error) {
      set({
        error: `An error occurred while deselecting spotlight participant, ${error}`,
      });
    }
  },
  startSession: async (sessionId: number) => {
    set({ sessionStatusLoading: true });
    try {
      await axiosInstance.put(`/sessions/${sessionId}/start`);
      set((state) => ({
        ...state,
        session: state.session
          ? { ...state.session, status: ESessionStatus.ONGOING }
          : state.session,
        sessionStatusLoading: false,
      }));
    } catch (error) {
      set({
        error: 'An error occurred while starting the session',
        sessionStatusLoading: false,
      });
    }
  },
  endSession: async (sessionId: number) => {
    set({ sessionStatusLoading: true });
    try {
      await axiosInstance.put(`/sessions/${sessionId}/end`);
      set((state) => ({
        ...state,
        session: state.session
          ? {
              ...state.session,
              status: ESessionStatus.ENDED,
              mode: ESessionMode.FOCUSED,
              spotlight_sharing: false,
              hands_raised: [],
            }
          : state.session,
        breakoutRoom: { room: null, token: null },
        proctoringRoom: { room: null, token: null },
        proctoringRooms: [],
        loading: false,
        error: null,
        sessionModeLoading: false,
        spotlightSharingLoading: false,
        handRaisedLoading: false,
        sessionStatusLoading: false,
        lk_room: undefined,
      }));
    } catch (error) {
      set({
        error: 'An error occurred while ending the session',
        sessionStatusLoading: false,
      });
    }
  },
}));

export default useSessionStore;
