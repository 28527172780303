import mqtt from 'mqtt';
import { nanoid } from 'nanoid';
import toast from 'react-hot-toast';
import screenfull from 'screenfull';

import { CONFIG } from '@/config';
import { BROADCAST_TARGET, GLOBAL_QUERY_CLIENT } from '@/constants';
import { getAccessorObject, getUserDetails } from '@/store';
import {
  getCurrentRegistration,
  getEventPrelude,
  getProgramAndRegistration,
  getRegistrationStreamLanguage,
} from '@/store/program';

import useEventStore from '@/store/eventStore';
import { hideLoadingOverlay, showLoadingOverlay, sleep } from '.';
import { axiosBridged } from './network';
import { getServerTime } from './timeKeeper';
// import mqtt from "mqtt";
let mqttClient: mqtt.MqttClient | null = null;
// let mqttClient: any = null;

const Subscriptions: string[] = [];
let groupChatSubscriber: (data: any) => void;

const allStatusColors = ['bg-red-700', 'bg-yellow-700', 'bg-green-700', 'bg-cyan-700'];

const setEMQXUIStatus = (status: number, message: string) => {
  try {
    const dotElement = document.getElementById('emqx-conn-dot');
    // const statusTextElement = document.getElementById('emqx-conn-status-text');
    if (dotElement) {
      switch (status) {
        case -1:
          dotElement.classList.remove(...allStatusColors);
          dotElement.classList.add('border-red-700');
          // statusTextElement.textContent = message;
          break;
        case 0:
          dotElement.classList.remove(...allStatusColors);
          dotElement.classList.add('border-yellow-700');
          // statusTextElement.textContent = message;
          break;
        case 1:
          dotElement.classList.remove(...allStatusColors);
          dotElement.classList.add('border-green-700');
          // statusTextElement.textContent = message;
          break;
        case 2:
          dotElement.classList.remove(...allStatusColors);
          dotElement.classList.add('border-cyan-700');
          // statusTextElement.textContent = message;
          break;

        default:
          break;
      }
    }
  } catch (error) {
    console.log('error in setEMQXUIStatus-->', error);
  }
};

const addToEventLog = (event: any) => {
  try {
    useEventStore.getState().addSentEvent(event);
    // const eventLogContainer = document.getElementById('emqx-event-log');
    // if (eventLogContainer) {
    //   const newLog = document.createElement('pre');
    //   newLog.textContent = JSON.stringify(event, null, 2);
    //   eventLogContainer.appendChild(newLog);
    // }
  } catch (error) {
    console.log('error in addToEventLog-->', error);
  }
};

const onConnect = () => {
  try {
    const { currentProgram, currentRegistration } = getProgramAndRegistration();
    currentProgram &&
      currentRegistration &&
      subscribeToProgramBroadcast(
        currentProgram.id,
        currentRegistration.batchId,
        currentRegistration.timezoneId,
      );
    setEMQXUIStatus(1, 'Connected');
    hideLoadingOverlay();
    console.log('MQTT CONNECT ');
  } catch (error) {
    console.log('error in onConnect-->', error);
  }
};

const onReConnect = () => {
  try {
    showLoadingOverlay();
    console.log('MQTT RECONNECT ', mqttClient);
    setEMQXUIStatus(0, 'Re Connecting...');
    // mqttClient && mqttClient.end()
  } catch (error) {
    console.log('error in onReConnect-->', error);
  }
};

const onMessage = (topic: string, rawMessage: Buffer) => {
  try {
    const parsedMessage: ISocketMessage = JSON.parse(rawMessage.toString());
    console.log('message ', parsedMessage);
    useEventStore.getState().addReceivedEvent(parsedMessage as any);
    const {
      type,
      message,
      refreshTarget,
      refreshTargetId,
      target = BROADCAST_TARGET.GLOABL,
      targetedBatches = [],
      targetedTags = [],
      // specificSession,
      // specificStep,
      fallbackLang = 'en',
      data,
    } = parsedMessage;
    switch (type) {
      case 'BROADCAST': {
        // const { currentStep, currentSession } = getCurrentStepAndSession();
        // const canShow =
        //   target === BROADCAST_TARGET.SESSION
        //     ? window.location.href.includes('session')
        //     : target === BROADCAST_TARGET.STEP
        //       ? window.location.href.includes('program')
        //       : true;
        // let specificityCheck = true;
        // if (specificSession) {
        //   specificityCheck = specificityCheck && currentSession?.id === specificSession;
        // }
        // if (specificStep) {
        //   specificityCheck = specificityCheck && currentStep?.id === specificStep;
        // }
        const currentRegistration = getCurrentRegistration();
        if (!currentRegistration) return;
        let canShow = false;

        if (targetedBatches.length > 0) {
          canShow = targetedBatches.includes(currentRegistration.batchId);

          if (targetedTags.length > 0) {
            canShow = canShow && targetedTags.includes(currentRegistration.tag!);
          }
        } else {
          canShow = true;
        }
        if (!canShow) return;
        const parsedMultiLangMessage = JSON.parse(message);
        const userLang = getRegistrationStreamLanguage();
        let targetLangMessage = parsedMultiLangMessage.find(
          (a: any) => a?.lang === userLang,
        )?.message;
        if (targetLangMessage === undefined) {
          if (fallbackLang) {
            targetLangMessage = parsedMultiLangMessage.find(
              (a: any) => a?.lang === fallbackLang,
            )?.message;
          }
        }
        if (canShow && targetLangMessage !== undefined) {
          toast.success(targetLangMessage, {
            duration: 10000,
            position: 'top-center',
            icon: '📢',
            style: {
              fontSize: '1.25rem',
              fontWeight: '600',
            },
          });
        }

        screenfull
          .exit()
          .then(() => console.log('exit'))
          .catch(() => console.log('error in exit fs'));
        break;
      }
      case 'EL_REFRESH': {
        if (!refreshTarget || !refreshTargetId) return;
        const eventPrelude = getEventPrelude();
        if (!eventPrelude) return;
        const { programId } = eventPrelude;
        switch (refreshTarget) {
          case 'STEP': {
            // refetch current step and set it in global state
            console.log('Refreshing steps');
            GLOBAL_QUERY_CLIENT.refetchQueries({
              queryKey: [programId, 'steps'],
            });

            break;
          }
          case 'SESSION': {
            // refetch current session and set it in global state
            GLOBAL_QUERY_CLIENT.refetchQueries({
              predicate: (query) => query.queryKey.includes(refreshTargetId),
              exact: false,
            });

            break;
          }
          case 'ELEMENT': {
            // refetch current session and set it in global state
            GLOBAL_QUERY_CLIENT.refetchQueries({
              // queryKey: [refreshTargetId],
              predicate: (query) => query.queryKey.includes(refreshTargetId),
              exact: false,
            });

            break;
          }

          default:
            break;
        }
        break;
      }
      case 'GROUP_CHAT_MESSAGE': {
        groupChatSubscriber?.(data);
        break;
      }

      default:
        break;
    }
  } catch (error) {
    console.log('error in onMessage-->', error);
  }
};

const onEndConnection = () => {
  try {
    console.log('MQTT END CONNECTION ');

    setEMQXUIStatus(-1, 'Disconnected');
  } catch (error) {
    console.log('error in onEndConnection-->', error);
  }
};

const generateToken = async (ssoId: string) => {
  try {
    const resp: IAPIResp = await axiosBridged.get('/registrations/emqxToken', {
      params: {
        ssoId,
      },
    });
    return resp.payload.token;
  } catch (error) {
    console.log('error in generateToken-->', error);
    throw error;
  }
};

export const initMqtt = async () => {
  try {
    const { ssoId } = getAccessorObject();
    if (ssoId === undefined) {
      console.log('cannot connect as there is not identifier');
      return;
    }
    const newClientId = `ipd-${ssoId}-${nanoid(16)}`;
    console.log('CONNECTING WITH ', newClientId);
    setEMQXUIStatus(0, 'Generating keys...');
    const token = ssoId && (await generateToken(ssoId));
    if (!token) {
      return;
    }
    setEMQXUIStatus(0, 'Connecting...');
    if (mqttClient) {
      if (mqttClient.connected) {
        return;
      }
    }
    mqttClient = mqtt.connect(CONFIG.emqxBrokerConfig.host, {
      ...CONFIG.emqxBrokerConfig,
      clientId: newClientId,
      password: token,
    });
    mqttClient.on('connect', onConnect);
    mqttClient.on('reconnect', onReConnect);
    mqttClient.on('message', onMessage);
    mqttClient.on('end', onEndConnection);
  } catch (error) {
    console.log('error in initMqtt-->', error);
  }
};

export const closeMqtt = () => {
  try {
    if (mqttClient) {
      console.log('ending');
      // mqttClient.end();
      // mqttClient = null
    }
  } catch (error) {
    console.log('error in closeMqtt-->', error);
  }
};

export const sendEvent = async (data: IEvent, channel = 'el-events') => {
  try {
    console.log('MQTT CONNECTION STATUS ---> ', mqttClient?.connected);
    if (mqttClient?.connected) {
      console.log(`sending event data on el-events -----> `, channel, data);
      addToEventLog(data);
      mqttClient.publish(
        channel,
        JSON.stringify(data),
        {
          qos: 1,
        },
        () => {
          setEMQXUIStatus(0, 'Connected');
        },
      );
    }
  } catch (error) {
    console.log('error in sendEvent-->', error);
    throw error;
  }
};

export const sendTimeEvent = (data: { targetElementId: string; eventType: string }) => {
  try {
    const { targetElementId, eventType } = data;
    const { programId, registrationId, timezoneId, batchId, tag, streamLanguage } =
      getEventPrelude();

    console.log('sending time event', data);
    sendEvent({
      id: nanoid(),
      type: eventType,
      programId,
      registrationId,
      timezoneId,
      batchId,
      elementId: targetElementId,
      ecg: '',
      createdAt: getServerTime(),
      updatedAt: getServerTime(),
      tag: tag || null,
      ...getAccessorObject(),
      streamLanguage,
    });
  } catch (error) {
    console.log('error in sendInTimeEvent-->', error);
    throw error;
  }
};

export const sendZoomInEvent = async (data: {
  targetElementId: string;
  eventType: string;
}) => {
  try {
    const { targetElementId, eventType } = data;
    const { programId, registrationId, timezoneId, batchId, tag, streamLanguage } =
      getEventPrelude();
    sendEvent({
      id: nanoid(),
      type: eventType,
      programId,
      registrationId,
      timezoneId,
      batchId,
      elementId: targetElementId,
      ecg: '',
      createdAt: getServerTime(),
      updatedAt: getServerTime(),
      tag: tag || null,
      ...getAccessorObject(),
      streamLanguage,
    });
  } catch (error) {
    console.log('error in senZoomInEvent-->', error);
    throw error;
  }
};

export const sendLapsedEvent = async (targetSessionId: string) => {
  try {
    const { programId, registrationId, timezoneId, batchId, tag, streamLanguage } =
      getEventPrelude();
    console.log('sending lapsed event for ', targetSessionId);
    sendEvent({
      id: nanoid(),
      type: 'SESSION_LAPSED',
      programId,
      registrationId,
      timezoneId,
      batchId,
      elementId: targetSessionId,
      ecg: '',
      createdAt: getServerTime(),
      updatedAt: getServerTime(),
      tag: tag || null,
      ...getAccessorObject(),
      streamLanguage,
    });
  } catch (error) {
    console.log('error in sendLapsedEvent-->', error);
    throw error;
  }
};

export const sendSessionMissedEvent = async (targetSessionId: string) => {
  try {
    const { programId, registrationId, timezoneId, batchId, tag, streamLanguage } =
      getEventPrelude();
    console.log('sending lapsed event for ', targetSessionId);
    sendEvent({
      id: nanoid(),
      type: 'SESSION_MISSED',
      programId,
      registrationId,
      timezoneId,
      batchId,
      elementId: targetSessionId,
      ecg: '',
      createdAt: getServerTime(),
      updatedAt: getServerTime(),
      tag: tag || null,
      ...getAccessorObject(),
      streamLanguage,
    });
  } catch (error) {
    console.log('error in sendSessionMissedEvent-->', error);
    throw error;
  }
};

export const sendQAndAEvent = async (elId: string, message: string) => {
  try {
    const { programId, registrationId, timezoneId, batchId, tag, streamLanguage } =
      getEventPrelude();
    const { firstName, lastName, userEmail } = getUserDetails();
    const payload = {
      id: nanoid(),
      ...getAccessorObject(),
      type: 'Q_AND_A',
      programId,
      registrationId,
      timezoneId,
      batchId,
      elementId: elId,
      ecg: '',
      createdAt: getServerTime(),
      updatedAt: getServerTime(),
      tag: tag || null,
      data: { message: message, name: `${firstName} ${lastName}`, email: userEmail },
      streamLanguage,
    };

    sendEvent(payload);
    // // sendEvent(payload, `QANDA/${timezoneId}-qana-events`);
    // sendEvent(payload, `QANDA/${elId}`);
  } catch (error) {
    console.log('error in sendQAndAEvent-->', error);
    throw error;
  }
};
export const sendVideoResumableEvent = async (elId: string, currentTime: number) => {
  try {
    const { programId, registrationId, timezoneId, batchId, tag, streamLanguage } =
      getEventPrelude();
    const payload = {
      id: nanoid(),
      ...getAccessorObject(),
      type: 'VIDEO_PLAY_HEAD_UPDATE',
      programId,
      registrationId,
      timezoneId,
      batchId,
      elementId: elId,
      ecg: '',
      createdAt: getServerTime(),
      updatedAt: getServerTime(),
      tag: tag || null,
      data: { playHeadTime: currentTime },
      streamLanguage,
    };
    sendEvent(payload);
  } catch (error) {
    console.log('error in sendQAndAEvent-->', error);
    throw error;
  }
};

export const sendVodPlayHeadPercentage = async (
  elId: string,
  currentTime: number,
  currentPercentage: number,
) => {
  try {
    const { programId, registrationId, timezoneId, batchId, tag, streamLanguage } =
      getEventPrelude();
    const payload = {
      id: nanoid(),
      ...getAccessorObject(),
      type: 'VOD_PLAY_HEAD_UPDATE',
      programId,
      registrationId,
      timezoneId,
      batchId,
      elementId: elId,
      ecg: '',
      createdAt: getServerTime(),
      updatedAt: getServerTime(),
      tag: tag || null,
      data: { playHeadTime: currentTime, currentPercentage },
      streamLanguage,
    };
    sendEvent(payload);
  } catch (error) {
    console.log('error in sendVideoPlayheadPercentage-->', error);
    throw error;
  }
};

export const sendECGEvent = async (data: {
  targetElementId: string;
  ecg: string;
  userId: string;
}) => {
  try {
    const { targetElementId, ecg, userId } = data;
    const { programId, registrationId, timezoneId, batchId, tag, streamLanguage } =
      getEventPrelude();
    sendEvent({
      id: nanoid(),
      // ssoId: userId,
      ...getAccessorObject(),
      type: 'ECG_UPDATE',
      programId,
      registrationId,
      timezoneId,
      batchId,
      elementId: targetElementId,
      ecg: ecg,
      createdAt: getServerTime(),
      updatedAt: getServerTime(),
      tag: tag || null,
      streamLanguage,
    });
  } catch (error) {
    console.log('error in sendECGEvent-->', error);
    throw error;
  }
};

export const sendFormDataEvent = (elId: string, formData: any) => {
  try {
    const { programId, registrationId, timezoneId, batchId, tag, streamLanguage } =
      getEventPrelude() || {};
    if (!programId || !registrationId || !timezoneId || !batchId || !streamLanguage) {
      throw new Error('programId not found for event prelude');
    }
    const payload = {
      id: nanoid(),
      ...getAccessorObject(),
      type: 'FORM_DATA_UPDATE',
      programId,
      registrationId,
      timezoneId,
      batchId,
      elementId: elId,
      ecg: '',
      createdAt: getServerTime(),
      updatedAt: getServerTime(),
      data: { ...formData },
      tag: tag || null,
      streamLanguage,
    };
    sendEvent(payload);
  } catch (error) {
    console.log('error in sendFormDataEvent-->', error);
    throw error;
  }
};
export const sendGroupChatMessageEvent = (elId: string, data: any) => {
  try {
    const { programId, registrationId, timezoneId, batchId, tag, streamLanguage } =
      getEventPrelude();
    const payload = {
      id: nanoid(),
      ...getAccessorObject(),
      type: 'GROUP_CHAT_MESSAGE',
      programId,
      registrationId,
      timezoneId,
      batchId,
      elementId: elId,
      ecg: '',
      createdAt: getServerTime(),
      updatedAt: getServerTime(),
      data,
      tag: tag || null,
      streamLanguage,
    };
    sendEvent(payload);
  } catch (error) {
    console.log('error in sendFormDataEvent-->', error);
    throw error;
  }
};

export const subscribeToProgramBroadcast = async (
  programId: string,
  batchId: string,
  timezoneId: string,
) => {
  try {
    if (mqttClient?.connected) {
      if (Subscriptions.length > 0) {
        unSubscribeToProgramBroadcast();
        await sleep(1500);
      }

      mqttClient.subscribe(`program/${programId}`, { qos: 1 }, () => {
        Subscriptions.push(`program/${programId}`);
        console.log(`subscribed to program/${programId}`);
      });
      mqttClient.subscribe(`batch/${batchId}`, { qos: 1 }, () => {
        Subscriptions.push(`batch/${batchId}`);
        console.log(`subscribed to batch/${batchId}`);
      });
      // mqttClient.subscribe(`timezone/${timezoneId}`, { qos: 1 }, () => {
      //   Subscriptions.push(`timezone/${timezoneId}`);
      //   console.log(`subscribed to timezone/${timezoneId}`);
      // });
    }
  } catch (error) {
    console.log('error in subscribeToProgramBroadcast-->', error);
    throw error;
  }
};

export const unSubscribeToProgramBroadcast = async () => {
  try {
    if (mqttClient?.connected) {
      console.log('UN SUBSCRIBING FROM ', Subscriptions);
      Subscriptions.forEach((topic) => {
        mqttClient?.unsubscribe(topic, () => {
          console.log(`unsubscribed from ${topic}`);
        });
      });
      // mqttClient.unsubscribe(`program/${programId}`, () => {
      //     console.log(`subscribed to el-broadcasts/${programId}`)
      // })
    }
  } catch (error) {
    console.log('error in unSubscribeToProgramBroadcast-->', error);
    throw error;
  }
};

export const subscribeToGroupChat = async (
  chatId: string,
  onNewMessage: (data: any) => void,
) => {
  try {
    unSubscribeToGroupChatUpdates(chatId);
    await sleep(1500);
    mqttClient?.subscribe(`group-chat/${chatId}`, { qos: 1 }, () => {
      Subscriptions.push(`group-chat/${chatId}`);
      groupChatSubscriber = onNewMessage;
      console.log(`subscribed to group-chat/${chatId}`);
    });
  } catch (error) {
    console.log('error in subscribeToGroupChat-->', error);
    throw error;
  }
};

export const unSubscribeToGroupChatUpdates = async (chatId: string) => {
  try {
    mqttClient?.unsubscribe(`group-chat/${chatId}`, () => {
      console.log(`unsubscribed from group-chat/${chatId}`);
    });
  } catch (error) {
    console.log('error in unSubscribeToGroupChatUpdates-->', error);
    throw error;
  }
};
