import { create } from 'zustand';

interface LoadingState {
    networkLoading: boolean;
    requestCount: number;
    startNetworkLoading: () => void;
    stopNetworkLoading: () => void;
}

const useLoadingStore = create<LoadingState>((set) => ({
    networkLoading: false,
    requestCount: 0,
    startNetworkLoading: () =>
        set((state) => {
            const newRequestCount = state.requestCount + 1;
            return {
                requestCount: newRequestCount,
                networkLoading: true,
            };
        }),
    stopNetworkLoading: () =>
        set((state) => {
            const newRequestCount = state.requestCount - 1;
            return {
                requestCount: Math.max(0, newRequestCount),
                networkLoading: newRequestCount > 0,
            };
        }),
}));

export default useLoadingStore;

