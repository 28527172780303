import { getAuthToken } from '@/store';
import axios from 'axios';
import { CONFIG } from '../config';
import useLoadingStore from '../store/loadingStore';

const loadingStore = useLoadingStore;

const axiosInstance = axios.create({
  baseURL: CONFIG.baseApiUrl,
});

axiosInstance.interceptors.request.use(
  (config) => {
    loadingStore.getState().startNetworkLoading();
    config.headers.Authorization = `Bearer ${getAuthToken()}`;
    return config;
  },
  (error) => {
    loadingStore.getState().stopNetworkLoading();
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => {
    loadingStore.getState().stopNetworkLoading();
    return response;
  },
  (error) => {
    loadingStore.getState().stopNetworkLoading();
    if (error.response && error.response.status === 401) {
      // Token expired or invalid
      window.location.replace('/logout');
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
