import { LivekitRoom } from '../interfaces';
import { ESessionMode, ISession, TSessionMode } from '../sharedModules/interfaces';
import useSessionStore from '../store/sessionStore';

const useSessionMode = () => {
    const {
        session,
        sessionModeLoading,
        putSessionMode,
        storeSessionMode,
    } = useSessionStore();

    const sessionMode = session?.mode ?? null;

    const changeSessionMode = async (
        session: ISession | null,
        sessionMode: TSessionMode | null,
        livestreamRoom: LivekitRoom,
    ) => {
        const mode =
            sessionMode === ESessionMode.FOCUSED ? ESessionMode.BREAKOUT : ESessionMode.FOCUSED;
        if (!session) {
            return;
        }
        try {
            await putSessionMode({
                sessionId: session.id,
                mode,
                room_name: livestreamRoom.room?.name ?? 'no-room',
            });
        } catch (error) {
            console.error('Error changing session mode:', error);
        }
    };

    return {
        putSessionMode,
        storeSessionMode,
        sessionMode,
        changeSessionMode,
        sessionModeLoading,
    };
};

export default useSessionMode;
