import {
  ControlBar,
  LiveKitRoom,
  VideoTrack,
  useRoomContext,
  useTracks,
} from '@livekit/components-react';
import { Room, Track } from 'livekit-client';
import { useEffect } from 'react';
import useParticipantSession from '../../../hooks/_useParticipantSession';
import useConfig from '../../../hooks/useConfig';
import { ESessionMode, TSessionMode } from '../../../sharedModules/interfaces';

const ProctoringRoomContainer: React.FC<IParticipantProctoringRoomProps> = ({
  sessionMode,
  isSelectedForSpotlightSharing,
  setProctoringRoom,
}) => {
  const proctoringRoom = useRoomContext();

  useEffect(() => {
    setProctoringRoom(proctoringRoom);
  }, [proctoringRoom, setProctoringRoom]);

  const tracks = useTracks([Track.Source.Camera, Track.Source.Unknown]);
  const localRef = tracks.find((track) => track.participant.isLocal);
  return (
    localRef && (
      <div className="local-video-track">
        <div
          style={{
            display:
              sessionMode === ESessionMode.FOCUSED && !isSelectedForSpotlightSharing
                ? 'flex'
                : 'none',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <VideoTrack trackRef={localRef} />
          <ControlBar />
        </div>
      </div>
    )
  );
};

interface IParticipantProctoringRoomProps {
  sessionMode: TSessionMode | null;
  isSelectedForSpotlightSharing: boolean;
  setProctoringRoom: (room: Room) => void;
}

const ParticipantProctoringRoom: React.FC<IParticipantProctoringRoomProps> = ({
  sessionMode,
  isSelectedForSpotlightSharing,
  setProctoringRoom,
}) => {
  const { proctoringRoom } = useParticipantSession();
  const { proctoringServerUrl } = useConfig();

  if (!proctoringRoom.token || !proctoringRoom.room) {
    return <div>Loading proctoring room...</div>;
  }

  return (
    <LiveKitRoom
      video={true}
      audio={false}
      screen={false}
      token={proctoringRoom.token}
      serverUrl={proctoringServerUrl}
    >
      <ProctoringRoomContainer
        sessionMode={sessionMode}
        isSelectedForSpotlightSharing={isSelectedForSpotlightSharing}
        setProctoringRoom={setProctoringRoom}
      />
    </LiveKitRoom>
  );
};

export default ParticipantProctoringRoom;
