import { CONFIG } from "../config";

const useConfig = () => {
  const { livestreamServerUrl, proctoringServerUrl, breakoutRoomsServerUrl } = CONFIG;
  return {
    livestreamServerUrl,
    proctoringServerUrl,
    breakoutRoomsServerUrl,
  };
};

export default useConfig;
