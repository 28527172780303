// Can be DEV,UAT,LOCAL_HOST,PROD
export const ENVIRONMENT_TYPE: string = import.meta.env.VITE_REACT_APP_ENV;

// ENVIRONMENT_TYPE !== 'PROD' && console.log(import.meta.env);

export interface configInterface {
  baseApiUrl: string;
  livestreamServerUrl: string;
  proctoringServerUrl: string;
  breakoutRoomsServerUrl: string;
}

interface mapInterface {
  DEV: configInterface;
  UAT: configInterface;
  PROD: configInterface;
}

const DEV_CONFIG: configInterface = {
  baseApiUrl: 'http://localhost:8080/api',
  livestreamServerUrl: 'wss://isha-ipd-poc-i34en7kf.livekit.cloud',
  proctoringServerUrl: 'wss://uipd-livekit-1.sadhguru.org',
  breakoutRoomsServerUrl: 'wss://uipd-livekit-2.sadhguru.org',
};

const UAT_CONFIG: configInterface = {
  baseApiUrl: 'https://uipd-web-conf-api.sadhguru.org/api',
  livestreamServerUrl: 'wss://isha-ipd-poc-i34en7kf.livekit.cloud',
  proctoringServerUrl: 'wss://uipd-livekit-1.sadhguru.org',
  breakoutRoomsServerUrl: 'wss://uipd-livekit-2.sadhguru.org',
};

const PROD_CONFIG: configInterface = {
  baseApiUrl: 'https://uipd-web-conf-api.sadhguru.org/api',
  livestreamServerUrl: 'wss://isha-ipd-poc-i34en7kf.livekit.cloud',
  proctoringServerUrl: 'wss://uipd-livekit-1.sadhguru.org',
  breakoutRoomsServerUrl: 'wss://uipd-livekit-2.sadhguru.org',
};

const CONFIG_MAP: mapInterface = {
  DEV: DEV_CONFIG,
  UAT: UAT_CONFIG,
  PROD: PROD_CONFIG,
};

const selector = () => {
  // console.log('ENVIRONMENT_TYPE', ENVIRONMENT_TYPE);
  switch (ENVIRONMENT_TYPE) {
    case 'DEV':
      return CONFIG_MAP.DEV;
    case 'UAT':
      return CONFIG_MAP.UAT;
    case 'PROD':
      return CONFIG_MAP.PROD;
    default:
      return CONFIG_MAP.UAT;
  }
};

export const CONFIG = selector();
