import { QueryClient } from '@tanstack/react-query';

import { ENVIRONMENT_TYPE } from '@/config';

export const TIMER_DEBUG_DISPLAY = localStorage.getItem('timerDebug') === 'true';

export enum ELEMENT_TYPE {
  RICH_TEXT = 'RICH_TEXT',
  RICH_TEXT_V2 = 'RICH_TEXT_V2',
  VIDEO = 'VIDEO',
  Q_AND_A = 'Q_AND_A',
  FORM = 'FORM',
  PADLET = 'PADLET',
  TABLE = 'TABLE',
  GROUP_CHAT = 'GROUP_CHAT',
  GROUP_CHAT_V2 = 'GROUP_CHAT_V2',
  LANG_SELECT = 'LANG_SELECT',
  VIDEO_SESSION = 'VIDEO_SESSION',
  ZOOM_SESSION = 'ZOOM_SESSION',
  TAG_SELECT = 'TAG_SELECT',
  VIDEO_V2 = 'VIDEO_V2',
}

export enum VIDEO_SOURCE_TYPE {
  HLS = 'HLS',
  DASH = 'DASH',
  YOUTUBE = 'YOUTUBE',
  NONE = 'NONE',
}

export const ACCUMULATED_BUFFER_TIME_THRESHOLD = 60;

export const ECG_START_OFFSET_IN_SECONDS = 0; /* 30 * 60 */
export const ECG_END_OFFSET_IN_SECONDS = 0; /* 30 * 60 */

export enum REGISTRATION_MODE {
  LIVE_SYNC = 'LIVE_SYNC',
  WALK_IN = 'WALK_IN',
  ANONYMOUS = 'ANONYMOUS',
}

export const MIN_DOWNLOAD_SPEED_REQUIRED = 3;

export enum BROADCAST_TARGET {
  GLOABL = 'STEP',
  TARGETED = 'SESSION',
}

export enum PROGRAM_AUTHENTICATION_MODE {
  SSO = 'SSO',
  TOKEN = 'TOKEN',
}

export const SHAKA_PLAYER_DEFAULT_VOD_CONFIG = {
  addSeekBar: true,
  addBigPlayButton: true,
  controlPanelElements: ['play_pause', 'volume', 'spacer', 'quality', 'fullscreen'],
  overflowMenuButtons: ['quality'],
  // volumeBarColors: {
  //   base: 'rgba(66, 133, 244, 0.5)',
  //   level: 'rgb(66, 133, 244)',
  // },
  singleClickForPlayAndPause: true,
};

export const SHAKA_PLAYER_DEFAULT_LIVE_CONFIG = {
  addSeekBar: false,
  addBigPlayButton: true,
  controlPanelElements: [
    'mute',
    'volume',
    'spacer',
    'cast',
    'airplay',
    'quality',
    'fullscreen',
  ],
  overflowMenuButtons: [],
  doubleClickForFullscreen: false,
  enableKeyboardPlaybackControls: false,
  enableFullscreenOnRotation: false,
  forceLandscapeOnFullscreen: false,
  singleClickForPlayAndPause: false,
};

export enum FORM_ELEMENT_TYPE {
  TEXT = 'TEXT',
  TEXTAREA = 'TEXTAREA',
  RADIO = 'RADIO',
  PHONE = 'PHONE',
  DATE = 'DATE',
}

export enum TABLE_ENTRY_TYPE {
  TEXT = 'TEXT',
  DATE_TIME = 'DATE_TIME',
}

export enum SESSION_MODE {
  NORMAL = 'NORMAL',
  DELAYED = 'DELAYED',
  NOT_ALLOWED = 'NOT_ALLOWED',
}

export enum VIDEO_SOURCE_ENCRYTION {
  NONE = 'NONE',
  DRM = 'DRM',
}

export enum VIDEO_PLAY_TYPE {
  LIVE = 'LIVE',
  VOD = 'VOD',
  LIVEY_VOD = 'LIVEY_VOD',
}

export const GLOBAL_QUERY_CLIENT: QueryClient = new QueryClient();

export const DOWNLOAD_ASSET =
  ENVIRONMENT_TYPE === 'PROD'
    ? 'https://ipd-cdn.sadhguru.org/ipd/test_7_mb_download.mp4'
    : 'https://ipd-cdn.sadhguru.org/ipd/test_7_mb_download.mp4';
// 'https://ipd-cdn.sadhguru.org/Sannidhi/image%201.webp';
