
export interface User {
  id: number;
  ssoId: string | null;
  email: string;
  password_hash: string;
  role: TRole | string;
  created_at: Date | null;
  updated_at: Date | null;
}

export interface Program {
  id: number;
  admin_id: number | null;
  name: string;
  created_at: Date | null;
  updated_at: Date | null;
}

export interface Module {
  id: number;
  program_id: number | null;
  index: number;
  name: string;
  created_at: Date | null;
  updated_at: Date | null;
}

export interface Batch {
  id: number;
  program_id: number | null;
  name: string;
  start_time: Date;
  duration: number;
  is_virtual: boolean;
  main_batch_id: number | null;
  offset_in_mins: number | null;
  created_at: Date | null;
  updated_at: Date | null;
}

export interface Session {
  id: number;
  program_id: number | null;
  batch_id: number | null;
  name: string;
  stream_language: string;
  mode: TSessionMode;
  status: TSessionStatus;
  spotlight_sharing: boolean | null;
  hands_raised?: HandRaised[];
  created_at: Date | null;
  updated_at: Date | null;
}

export enum ESessionStatus {
  UPCOMING = "UPCOMING",
  ONGOING = "ONGOING",
  PAUSED = "PAUSED",
  ENDED = "ENDED",
}

export type TSessionStatus = keyof typeof ESessionStatus;


export interface HandRaised {
  id: number;
  user_id: number | null;
  participant_name: string | null;
  participant_id: string;
  room_name: string;
  is_selected: boolean | null;
  dropped_off: boolean | null;
  created_at: Date | null;
  updated_at: Date | null;
}

export interface ILanguage {
  code: string;
  name: string;
}

export enum ERole {
  PARTICIPANT = "PARTICIPANT",
  CO_HOST = "CO_HOST",
  HOST = "HOST",
  ADMIN = "ADMIN",
  SUPER_ADMIN = "SUPER_ADMIN",
}

export type TRole = keyof typeof ERole;

export interface IRole {
  name: string;
  showForRegistration: boolean;
}

export interface ICoHost {
  id: number;
  email: string;
}

export interface ISessionCoHost extends ICoHost {
  proctor: boolean;
  control: boolean;
}

export interface Config {
  port: number;
  jwtSecret: string;
}

export enum ERoomType {
  LIVESTREAM = "LIVESTREAM",
  BREAKOUT = "BREAKOUT",
  PROCTORING = "PROCTORING",
}

export type RoomType =
  | ERoomType.LIVESTREAM
  | ERoomType.BREAKOUT
  | ERoomType.PROCTORING;

export enum ESessionMode {
  FOCUSED = "FOCUSED",
  BREAKOUT = "BREAKOUT",
}

export type TSessionMode = keyof typeof ESessionMode;

export interface ISession {
  id: number;
  name: string;
  program_id: number;
  batch_id: number;
  stream_language: string;
  status: TSessionStatus;
  mode: TSessionMode;
  host: {
    name: string;
    ssoId: string;
    email: string;
  };
  spotlight_sharing: boolean | null;
  hands_raised?: IHandRaised[];
  created_at: Date;
  updated_at: Date;
}

export interface IHandRaised {
  user_id: number;
  session_id: number;
  participant_name: string;
  participant_id: string;
  is_selected?: boolean;
  droppedOff?: boolean;
}

export interface IProgram {
  id: number;
  name: string;
  registeredSessions: Array<{
    sessionId: number;
    sessionName: string;
  }>;
}

export interface IAdminProgramConfig {
  id: number;
  name: string;
  admin: User;
  hosts: User[];
  cycles: Array<{
    id: number;
    name: string;
    index: number;
    modules: Array<{
      id: number;
      name: string;
      index: number;
      batches: Array<{
        id: number;
        name: string;
        start_time: Date;
        duration: number;
        index: number;
        is_virtual: boolean;
        offset_in_mins: number;
      }>;
    }>;
  }>;
}

export interface IAssignCoHostsResponse {
  message: string;
  co_hosts: ISessionCoHost[];
}
