import { useRoomContext } from '@livekit/components-react';
import React, { memo, useCallback } from 'react';
import useSession from '../../../hooks/useSession';
import useSpotlightSharing from '../../../hooks/useSpotlightSharing';

interface ParticipantControlsProps {
  isSelectedForSpotlightSharing: boolean;
  setIsSelectedForSpotlightSharing: (isSelected: boolean) => void;
}

const ParticipantControls: React.FC<ParticipantControlsProps> = memo(
  ({ isSelectedForSpotlightSharing, setIsSelectedForSpotlightSharing }) => {
    const { session } = useSession();
    const {
      spotlightSharing,
      handRaisedLoading,
      handRaised,
      handleLocalParticipantRaiseHand,
    } = useSpotlightSharing();
    const room = useRoomContext();

    const handleEndSharing = useCallback(async () => {
      // Disable camera and microphone
      room.localParticipant.setCameraEnabled(false);
      room.localParticipant.setMicrophoneEnabled(false);

      // Lower the hand
      handleLocalParticipantRaiseHand(handRaised, session, room);
      setIsSelectedForSpotlightSharing(false);
    }, [
      room,
      handRaised,
      session,
      handleLocalParticipantRaiseHand,
      setIsSelectedForSpotlightSharing,
    ]);

    const handleRaiseHand = useCallback(() => {
      handleLocalParticipantRaiseHand(handRaised, session, room);
    }, [handRaised, handleLocalParticipantRaiseHand, room, session]);

    return (
      <>
        {isSelectedForSpotlightSharing ? (
          <div className="spotlighted-controls flex flex-col items-center p-2 gap-1">
            <p>You have been selected for sharing</p>
            <button onClick={handleEndSharing} className="btn btn-neutral">
              End Sharing
            </button>
          </div>
        ) : (
          spotlightSharing && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                border: '1px solid white',
                marginTop: '0.5em',
              }}
            >
              <button
                onClick={handleRaiseHand}
                disabled={handRaisedLoading}
                className="btn btn-neutral"
              >
                {handRaisedLoading ? (
                  <span className="loading loading-spinner loading-sm"></span>
                ) : handRaised ? (
                  'Lower Hand'
                ) : (
                  'Raise Hand'
                )}
              </button>
            </div>
          )
        )}
      </>
    );
  },
);

export default ParticipantControls;
