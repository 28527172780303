import { getUserDetails } from '@/store';
import {
  LiveKitRoom,
  RoomAudioRenderer,
  TrackReference,
  VideoTrack,
  useRoomContext,
  useTracks,
} from '@livekit/components-react';
import { Room, RoomEvent, Track } from 'livekit-client';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { videoCaptureOptions } from '../../../constants/session';
import useParticipantSession from '../../../hooks/_useParticipantSession';
import useConfig from '../../../hooks/useConfig';
import useSession from '../../../hooks/useSession';
import useSessionMode from '../../../hooks/useSessionMode';
import useSessionStatus from '../../../hooks/useSessionStatus';
import useSpotlightSharing from '../../../hooks/useSpotlightSharing';
import SpotlightedParticipant from '../shared/SpotlightedParticipant';
import ParticipantControls from './ParticipantControls';

interface LivestreamVideoProps {
  token: string;
  room: Room | null;
  isSelectedForSpotlightSharing: boolean;
  setIsSelectedForSpotlightSharing: (isSelected: boolean) => void;
}

const ParticipantLivestreamContainer: React.FC<{
  isSelectedForSpotlightSharing: boolean;
  setIsSelectedForSpotlightSharing: (isSelected: boolean) => void;
}> = ({ isSelectedForSpotlightSharing, setIsSelectedForSpotlightSharing }) => {
  const { userEmail: email } = getUserDetails();
  const { session } = useSession();
  const { handleParticipantLivestreamRoomData, getParticipantTokens, hostUsername } =
    useParticipantSession();
  const { storeSessionStatus } = useSessionStatus();
  const { storeSessionMode } = useSessionMode();
  const {
    storeSpotlightSharing,
    storeHandsRaised,
    handsRaised,
    checkIsSelectedForSpotlightSharing,
  } = useSpotlightSharing();
  const room = useRoomContext();
  const liveStreamRoomRef = useRef(room);

  useEffect(() => {
    liveStreamRoomRef.current = room;
  }, [room]);

  useEffect(() => {
    const isSelected = checkIsSelectedForSpotlightSharing(
      handsRaised,
      liveStreamRoomRef.current.localParticipant.identity,
    );
    setIsSelectedForSpotlightSharing(isSelected);
    if (isSelected) {
      liveStreamRoomRef.current.localParticipant.setCameraEnabled(
        true,
        videoCaptureOptions,
      );
      liveStreamRoomRef.current.localParticipant.setMicrophoneEnabled(true);
    }
  }, [checkIsSelectedForSpotlightSharing, handsRaised, setIsSelectedForSpotlightSharing]);

  const hostTrackReferences: TrackReference[] = useTracks([
    Track.Source.ScreenShare,
    Track.Source.Unknown,
    Track.Source.Camera,
  ]);

  const hostStreamTrackRef = useMemo(
    () =>
      hostTrackReferences.find(
        (trackRef) => trackRef.participant.identity === `${hostUsername}_stream`,
      ) || null,
    [hostTrackReferences, hostUsername],
  );

  const handleParticipantDataCallback = useCallback(
    (payload: Uint8Array) => {
      handleParticipantLivestreamRoomData(
        payload,
        handsRaised,
        storeSessionMode,
        storeSpotlightSharing,
        storeHandsRaised,
        storeSessionStatus,
        getParticipantTokens,
        liveStreamRoomRef.current,
        email ?? '',
        session?.id ?? -1,
      );
    },
    [
      handleParticipantLivestreamRoomData,
      handsRaised,
      storeSessionMode,
      storeSpotlightSharing,
      storeHandsRaised,
      storeSessionStatus,
      getParticipantTokens,
      email,
      session,
    ],
  );

  useEffect(() => {
    // console.log('ParticipantLivestreamContainer useEffect');

    if (!room) return; // Ensure room is available

    room.on(RoomEvent.DataReceived, handleParticipantDataCallback);

    return () => {
      room.off(RoomEvent.DataReceived, handleParticipantDataCallback);
    };
  }, [handleParticipantDataCallback, room]);

  useEffect(() => {
    console.log('ParticipantLivestreamContainer mounted');

    return () => {
      const currentLiveStreamRoom = liveStreamRoomRef.current;

      console.log('ParticipantLivestreamContainer will unmount');
      if (currentLiveStreamRoom) {
        console.log('Disconnecting from liveStreamRoom');
        currentLiveStreamRoom.disconnect();
      }
    };
  }, []);

  return (
    <>
      <div
        className="host-video-container"
        style={{
          aspectRatio: 'calc(16 / 9)',
          minWidth: '25%',
          outline: '1px solid lightgrey',
        }}
      >
        {hostStreamTrackRef ? (
          <>
            <VideoTrack
              className="host-video"
              trackRef={hostStreamTrackRef}
              style={{ border: '5px solid white' }}
            />
          </>
        ) : (
          <div style={{ textAlign: 'center' }}>Host is offline</div>
        )}
        <SpotlightedParticipant />
      </div>
      <ParticipantControls
        isSelectedForSpotlightSharing={isSelectedForSpotlightSharing}
        setIsSelectedForSpotlightSharing={setIsSelectedForSpotlightSharing}
      />
    </>
  );
};

const ParticipantLivestreamRoom: React.FC<LivestreamVideoProps> = ({
  token,
  setIsSelectedForSpotlightSharing,
  isSelectedForSpotlightSharing,
}) => {
  const { livestreamServerUrl } = useConfig();
  const [connected, setConnected] = useState(false);

  return (
    <LiveKitRoom
      audio={false}
      video={false}
      screen={false}
      token={token}
      serverUrl={livestreamServerUrl}
      connect={true}
      onConnected={() => setConnected(true)}
      onDisconnected={() => setConnected(false)}
      onError={(error) => console.error('LiveKit error:', error)}
      className="flex flex-col items-center justify-center"
      options={{ dynacast: true }}
    >
      {connected ? (
        <ParticipantLivestreamContainer
          isSelectedForSpotlightSharing={isSelectedForSpotlightSharing}
          setIsSelectedForSpotlightSharing={setIsSelectedForSpotlightSharing}
        />
      ) : (
        <div>Loading livestream...</div>
      )}
      <RoomAudioRenderer />
    </LiveKitRoom>
  );
};

export default ParticipantLivestreamRoom;
