import { useEffect, useRef, useState } from 'react';

interface CompatibilityTestProps {
  onPermissionsChecked: (
    hasCameraPermission: boolean,
    hasMicrophonePermission: boolean,
  ) => void;
}

const CompatibilityTest = ({ onPermissionsChecked }: CompatibilityTestProps) => {
  const [hasCameraPermission, setHasCameraPermission] = useState(false);
  const [hasMicrophonePermission, setHasMicrophonePermission] = useState(false);
  const [cameraChecked, setCameraChecked] = useState(false);
  const [microphoneChecked, setMicrophoneChecked] = useState(false);
  const [stream, setStream] = useState<MediaStream | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    const checkPermissions = async () => {
      try {
        // Check for camera permission
        try {
          await navigator.mediaDevices.getUserMedia({ video: true });
          setHasCameraPermission(true);
        } catch (error) {
          console.warn('Camera permission denied:', error);
        }
        setCameraChecked(true);

        // Check for microphone permission
        try {
          // await navigator.mediaDevices.getUserMedia({ audio: true }); // TODO: fix this
          setHasMicrophonePermission(true);
        } catch (error) {
          console.warn('Microphone permission denied:', error);
        }
        setMicrophoneChecked(true);

        // If both permissions are granted, get the combined stream
        if (hasCameraPermission && hasMicrophonePermission) {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: true,
            audio: true,
          });
          setStream(stream);
        }
      } catch (error) {
        console.error('Error accessing media devices:', error);
      }
    };

    checkPermissions();

    return () => {
      stream?.getTracks().forEach((track) => track.stop());
    };
  }, [hasCameraPermission, hasMicrophonePermission]);

  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);

  useEffect(() => {
    onPermissionsChecked(hasCameraPermission, hasMicrophonePermission);
  }, [hasCameraPermission, hasMicrophonePermission]);

  return (
    <div className="compatibility-test">
      <h2>Compatibility Test</h2>
      <div>
        {stream && (
          <video autoPlay muted playsInline ref={videoRef}>
            Video stream is not available
          </video>
        )}
        {!hasCameraPermission && cameraChecked && (
          <div>
            <p>Please enable camera access.</p>
            <img
              src="/path/to/camera-permission-instructions.png"
              alt="Enable camera instructions"
            />
          </div>
        )}
        {!hasMicrophonePermission && microphoneChecked && (
          <div>
            <p>Please enable microphone access.</p>
            <img
              src="/path/to/microphone-permission-instructions.png"
              alt="Enable microphone instructions"
            />
          </div>
        )}
        <p>After enabling permissions, please refresh the page.</p>
      </div>
    </div>
  );
};

export default CompatibilityTest;
