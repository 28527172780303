import { getUserDetails } from '@/store';
import { Room } from 'livekit-client';
import { useCallback, useMemo } from 'react';
import { LivekitRoom } from '../interfaces';
import { IHandRaised, ISession } from '../sharedModules/interfaces';
import useSessionStore from '../store/sessionStore';

const useSpotlightSharing = () => {
  const { userEmail: email } = getUserDetails();

  const {
    session,
    spotlightSharingLoading,
    handRaisedLoading,
    putSpotlightSharing,
    storeSpotlightSharing,
    putRaisedHand,
    storeHandsRaised,
    putSelectSpotlightParticipant,
    putDeselectSpotlightParticipant,
    isSelectedForSpotlightSharing,
    setIsSelectedForSpotlightSharing,
  } = useSessionStore();

  const spotlightSharing = session?.spotlight_sharing ?? null;

  const handsRaised = useMemo(() => session?.hands_raised ?? [], [session?.hands_raised]);
  const handRaised = handsRaised.some(
    (hand: IHandRaised) => hand.participant_name === email,
  );

  const spotlightedParticipant = handsRaised.find(
    (hand: IHandRaised) => hand.is_selected,
  )?.participant_name;

  const checkIsSelectedForSpotlightSharing = useCallback(
    (handsRaised: IHandRaised[], participantIdentity: string): boolean => {
      return handsRaised.some(
        (hand: IHandRaised) =>
          hand.participant_name === participantIdentity && hand.is_selected,
      );
    },
    [],
  );

  const toggleSpotlightSharing = async (
    session: ISession | null,
    livestreamRoom: LivekitRoom,
  ) => {
    if (!session) {
      return;
    }
    try {
      await putSpotlightSharing({
        sessionId: session.id,
        spotlight_sharing: !session.spotlight_sharing,
        room_name: livestreamRoom.room?.name ?? 'no-room',
      });
    } catch (error) {
      console.error('Error toggling spotlight sharing:', error);
    }
  };

  const handleLocalParticipantRaiseHand = async (
    handRaised: boolean,
    session: ISession | null,
    room: Room,
  ) => {
    await putRaisedHand(handRaised, {
      session_id: session?.id ?? -1,
      roomName: room.name,
      participantName: email ?? '',
      participantId: room.localParticipant.sid,
    });
  };

  const handleToggleSpotlight = useCallback(
    async (user_id: number, isSelected: boolean, sessionId?: number) => {
      if (!sessionId) return;

      try {
        if (isSelected) {
          await putDeselectSpotlightParticipant({ sessionId, user_id });
        } else {
          await putSelectSpotlightParticipant({ sessionId, user_id });
        }
        const updatedHandsRaised = handsRaised.map((hand: IHandRaised) =>
          hand.user_id === user_id
            ? { ...hand, is_selected: !isSelected }
            : { ...hand, is_selected: false },
        );
        storeHandsRaised(updatedHandsRaised);
      } catch (error) {
        console.error('Error toggling spotlight:', error);
      }
    },
    [
      handsRaised,
      storeHandsRaised,
      putDeselectSpotlightParticipant,
      putSelectSpotlightParticipant,
    ],
  );

  return {
    putSpotlightSharing,
    storeSpotlightSharing,
    putRaisedHand,
    storeHandsRaised,
    spotlightSharing,
    handsRaised,
    spotlightSharingLoading,
    handRaisedLoading,
    handRaised,
    spotlightedParticipant,
    checkIsSelectedForSpotlightSharing,
    toggleSpotlightSharing,
    handleLocalParticipantRaiseHand,
    isSelectedForSpotlightSharing,
    setIsSelectedForSpotlightSharing,
    handleToggleSpotlight,
    putDeselectSpotlightParticipant,
  };
};

export default useSpotlightSharing;
