import { LiveKitRoom, TrackToggle } from '@livekit/components-react';
import { Room, Track } from 'livekit-client';
import useConfig from '../../../hooks/useConfig';
import { BreakoutRoomVideoConference } from './BreakoutRoomVideoConference';

interface BreakoutRoomProps {
  token: string;
  room: Room;
  // isSelectedForSpotlightSharing: boolean;
  spotlightedParticipant: string;
  onCameraToggle: (enabled: boolean, isUserInitiated: boolean) => void;
}

const BreakoutRoom: React.FC<BreakoutRoomProps> = ({
  token,
  room,
  // isSelectedForSpotlightSharing,
  spotlightedParticipant,
  onCameraToggle,
}) => {
  const { breakoutRoomsServerUrl } = useConfig();

  if (!token || !room) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {
        <LiveKitRoom
          audio={false}
          video={true}
          screen={false}
          token={token}
          serverUrl={breakoutRoomsServerUrl}
          connect={true}
          onMediaDeviceFailure={(failure) => {
            console.error('Media device failure:', failure);
          }}
          onConnected={() => {
            console.log('Connected to LiveKit breakoutroom');
          }}
          onDisconnected={() => {
            console.log('Disconnected from LiveKit breakoutroom');
          }}
          onError={(error) => {
            console.error('LiveKit error:', error);
          }}
          options={{ dynacast: true }}
        >
          <BreakoutRoomVideoConference
            // isSelectedForSpotlightSharing={isSelectedForSpotlightSharing}
            spotlightedParticipant={spotlightedParticipant}
          />
          <div className="lk-button-group">
            <TrackToggle
              style={{ display: 'none' }}
              source={Track.Source.Camera}
              showIcon={true}
              onChange={onCameraToggle}
            >
              {'Camera'}
            </TrackToggle>
          </div>
        </LiveKitRoom>
      }
    </>
  );
};

export default BreakoutRoom;
