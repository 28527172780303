import useSessionStore from '../store/sessionStore';

const useSession = () => {
  const {
    session,
    livestreamRoom,
    loading,
    error,
    fetchSessionById,
  } = useSessionStore();

  return {
    fetchSessionById,
    session,
    livestreamRoom,
    loading,
    error,
  };
};

export default useSession;
