import { VideoTrack, useTracks } from '@livekit/components-react';
import { Track } from 'livekit-client';
import useSpotlightSharing from '../../../hooks/useSpotlightSharing';

const SpotlightedParticipant: React.FC = () => {
  const tracks = useTracks();
  const { spotlightedParticipant } = useSpotlightSharing();

  // console.log(tracks, spotlightedParticipant);

  const spotlightedTrack = tracks.find(
    (track) =>
      track.participant.identity === spotlightedParticipant &&
      track.source === Track.Source.Camera,
  );

  // console.log('spotlightedTrack:', spotlightedTrack);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {spotlightedTrack && (
        <VideoTrack className="spotlighted-participant" trackRef={spotlightedTrack} />
      )}
      {spotlightedParticipant && spotlightedTrack && (
        <div className="spotlighted-participant-label">{spotlightedParticipant}</div>
      )}
    </div>
  );
};

export default SpotlightedParticipant;
